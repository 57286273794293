.container.custom-channel-message-history {
  padding: 0;
}

.container.custom-channel-message-history .card {
  margin: 32px 24px;
}

.container.custom-channel-message-history .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.custom-channel-message-history .row {
  margin-bottom: 24px;
}

.container.custom-channel-message-history tfoot > tr > td > div > p {
  margin: 0;
}

.container.custom-channel-message-history thead > tr > th {
  color: var(--primary);
  background-color: var(--bg-white);
  border-color: var(--primary);
}

.container.custom-channel-message-history p {
  margin-bottom: 0;
}
