.custom-channel-message-history-form {
  .row {
    row-gap: 16px;
  }

  .dropdown {
    width: 0px !important;
    min-width: 100%;
  }
}
