.message-details-info {
  .side-informations {
    display: flex;
    flex-direction: column;
  }
}

.message-details-info.status {
  height: 43px;
  font-weight: 700;
}

.message-details-info.status svg {
  min-width: fit-content;
  margin-right: 8px;
}

.message-details-info .input > .card-text > .icon-btn {
  margin-left: 8px;
}

.json-viewer {
  height: 300px;
}

.error-details {
  .error-code {
    color: red;
  }
}

.rt-link {
  justify-content: end;
}

.error-details-container {
  margin-top: 10px;
}

.error-icon {
  margin-left: 8px;
  margin-top: 3px;
}

.message-details-header {
  display: flex;
}
